import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StorageServiceModule} from 'ngx-webstorage-service';
import { environment } from 'src/environments/environment';
import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SafePipe } from './pipes/safe.pipe';
import { HomeComponent } from './components/home/home.component';
import { BrochureComponent } from './components/brochure/brochure.component';

import { ApiInterceptor } from './services/interceptors/api.interceptor';
import { SessionService } from './services/storage/session.service';
import { ScanComponent } from './components/scan/scan.component';
import { FormComponent } from './components/form/form.component';


export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        BrochureComponent,
        SafePipe,
        ScanComponent,
        FormComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ModalModule.forRoot(),
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        StorageServiceModule,
        QRCodeModule
    ],
    providers: [
        SafePipe,
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }, 
        SessionService, 
        {provide: 'googleTagManagerId',  useValue: environment.analytics }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
