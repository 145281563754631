import { Component } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '../environments/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'RCI Latam';
  identifier: string = '';
  mobile: boolean = false;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private gtmService: GoogleTagManagerService
  ) {
    // Insert GTAG
    let el = document.createElement('script');
    el.setAttribute(
      'src',
      'https://www.googletagmanager.com/gtag/js?id=' + environment.analytics
    );
    el.setAttribute('async', 'async');
    document.body.appendChild(el);

    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);
    appHeight();

    // Add languages
    translate.addLangs(Object.keys(environment.langs));
    translate.setDefaultLang('en');

    // Detect if mobile
    this.mobile =
      this.deviceService.isMobile() || this.deviceService.isTablet();
  }

  ngOnInit() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (this.activatedRoute.firstChild.firstChild) {
          this.identifier =
            this.activatedRoute.firstChild.firstChild.firstChild.data[
              '_value'
            ].id;
        } else if (this.activatedRoute.firstChild.data) {
          this.identifier = this.activatedRoute.firstChild.data['_value'].id;
        }

        // Tag Manager
        if (!event.url.includes('undefined')) {
          const gtmTag = { event: 'page', pageName: event.url };
          this.gtmService.pushTag(gtmTag);
        }
      }
    });
  }
}
