import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  langs: any = [];
  lang: string = 'en';
  code: string = '';

  constructor(protected router: Router, protected activated: ActivatedRoute) {
    this.code = environment.default;
    this.langs = [];

    for (let p in environment.langs) {
      this.langs.push({ lang: p, label: environment.langs[p] });
    }

    this.activated.paramMap.subscribe((paramMap) => {
      if (paramMap.has('code')) this.code = paramMap.get('code');
    });
  }

  enter() {
    this.router.navigate(['/' + this.code + '/' + this.lang]);
  }
}
