import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BrochureComponent } from './components/brochure/brochure.component';
import { ScanComponent } from './components/scan/scan.component';
import { FormComponent } from './components/form/form.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { id: 'home' },
  },
  {
    path: 'form/:lang',
    component: FormComponent
  },
  {
    path: 'scan/:lang',
    component: ScanComponent,
    data: { id: 'scan' },
  },
  {
    path: ':code',
    component: HomeComponent,
    data: { id: 'home' },
  },
  {
    path: ':code/:lang',
    component: BrochureComponent,
    data: { id: 'brochure' },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
