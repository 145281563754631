import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit {
  loading: boolean = false;
  lang: string = '';
  forms: any = {
    es: 'assets/form.html',
    pt: 'assets/form-pt.html',
    en: '',
  };

  constructor(private route: ActivatedRoute) {
    this.loading = true;
    this.lang = this.route.snapshot.paramMap.get('lang');

    if (this.lang === 'en') {
      window.location.href =
        'https://dl.rcibrochure.com/download/RCI_BROCHURE_EN_SCANME.pdf';
    }

    this.loading = false;
    
    window.addEventListener('message', function (e) {
      // Get the sent data
      const data = e.data;

      if (data.resort) {
        const resort = data.resort;

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'envio_de_formulario',
          resort: resort,
        });
      }
    });
  }

  ngOnInit(): void {}

  catchFormSubmitted(): void {}
}
