import { Token } from './token.model';
import { Type } from "class-transformer";

export class User {

	id?:number;
	name:string 	 = '';
    lastname:string  = '';
	email:string 	 = '';
	
   	@Type(() => Token)
	token?:Token;

}
