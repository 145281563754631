import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-scan',
    templateUrl: './scan.component.html',
    styleUrls: ['./scan.component.scss']
})

export class ScanComponent implements OnInit {

  constructor(public translate: TranslateService, protected activated: ActivatedRoute) {

    this.activated.paramMap.subscribe(paramMap => {
            
        let lang = paramMap.get('lang');
        this.translate.use(lang);

        this.translate.get('scan.link').subscribe((translated: string) => {
            setTimeout(()=>{
                window.location.href = translated;
            }, 500);
        });
        
    });
  }

  ngOnInit(): void {
  }

}
