<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col text-center col-l">
      <img
        src="/assets/images/start/img-logo-rci.svg"
        class="img-fluid"
        width="80"
      />
    </div>

    <div class="col col-r pt-5 text-center text-md-start">
      <div class="ps-0 ps-md-5">
        <ng-select
          [(ngModel)]="lang"
          [items]="langs"
          [bindValue]="'lang'"
          [clearable]="false"
          [searchable]="false"
          class="mx-auto mx-md-0 text-start"
        >
          <ng-template ng-label-tmp let-item="item">
            <img src="/assets/langs/{{ item.lang }}.png" /> {{ item.label }}
          </ng-template>

          <ng-template
            ng-option-tmp
            let-item="item"
            let-index="index"
            let-search="searchTerm"
          >
            <span
              class="ng-option-label ng-star-inserted"
              ng-reflect-ng-item-label="{{ item.label }}"
              ng-reflect-escape="true"
            >
              <img src="/assets/langs/{{ item.lang }}.png" />

              {{ item.label }}
            </span>
          </ng-template>
        </ng-select>

        <div class="mt-3">
          <button
            class="{{ lang.toUpperCase() }}"
            id="home-enter"
            (click)="enter()"
          >
            Enter
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<img src="/assets/images/home/img-bar.jpg" class="home-bar img-fluid" />
