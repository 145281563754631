<div *ngIf="loaded">
  <!-- HOME BUTTON -->
  <div id="scroll-up" class="{{ start ? 'show' : '' }}">
    <a pageScroll href="/{{ code }}/{{ lang }}#welcome">
      <img src="/assets/images/common/img-house.png" alt="" />
    </a>
  </div>
  <!-- -->

  <!-- LANG SELECTOR -->
  <div id="lang" class="{{ start ? 'show' : '' }}">
    <ng-select
      [(ngModel)]="lang"
      [items]="langs"
      [bindValue]="'lang'"
      [clearable]="false"
      [searchable]="false"
      class="mx-auto mx-md-0 text-start"
      (change)="change($event)"
    >
      <ng-template ng-label-tmp let-item="item">
        <img src="/assets/langs/{{ item.lang }}.png" />
      </ng-template>

      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <span
          class="ng-option-label ng-star-inserted"
          ng-reflect-ng-item-label="{{ item.label }}"
          ng-reflect-escape="true"
          ><img src="/assets/langs/{{ item.lang }}.png" />
          {{ item.label }}</span
        >
      </ng-template>
    </ng-select>
  </div>
  <!-- -->

  <!-- HOME SECTION -->
  <section id="start">
    <div class="row">
      <div class="col-12 col-md-8 col-text order-2 order-md-1 mt-auto">
        <div class="start-text">
          <h1
            data-aos="fade-in"
            class="text-center text-md-start"
            data-aos-easing="ease-in-out"
            data-aos-offset="200"
            data-aos-delay="50"
            [innerHTML]="'start.all-in-one' | translate"
          ></h1>

          <p [innerHTML]="'start.purchasing' | translate"></p>
        </div>
      </div>

      <div
        class="col-12 col-md-auto mx-auto text-end my-4 pt-4 pe-4 order-1 order-md-2"
      >
        <img
          data-aos="fade-down"
          src="{{logo }}"
          class="start-logo"
          alt="RCI"
        />
      </div>

      <div class="col-12 align-self-end p-0 col-img position-relative order-3">
        <img src="{{ imgs }}" alt="Logo" class="main-img" />

        <div class="circle">
          <img
            data-aos="fade-in"
            src="/assets/images/start/img-circle.png"
            alt=""
          />
        </div>

        <img
          src="/assets/images/start/img-tiles.png"
          class="start-tiles d-none d-md-block lines"
          alt=""
        />

        <div class="line-bottom"></div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- SHAPE SECTION -->
  <section id="shape">
    <div class="shape-div shape-div-1"></div>
    <div class="shape-div shape-div-2-1"></div>
    <div class="shape-div shape-div-2-2"></div>
    <div class="shape-div shape-div-3"></div>

    <div class="d-flex pt-5 justify-content-center justify-content-md-between">
      <div class="shape-text text-center text-md-start d-flex">
        <div>
          <img src="/assets/images/shape/icon-bag.png" class="mt-4" alt="" />
        </div>

        <div>
          <h3 [innerHTML]="'shape.rci' | translate"></h3>
          <p [innerHTML]="'shape.new-shape' | translate"></p>
        </div>
      </div>
    </div>

    <div
      class="shape-image justify-content-center video-link"
      [style.background-image]="
        'url(' + ('shape.image' | translate) + ')' | safe : 'resourceURL'
      "
      (click)="show('shape.video')"
      [attr.data-video]="'Shape'"
      data-aos="fade-in"
    ></div>
  </section>
  <!-- -->

  <!-- WELCOME SECTION -->
  <section id="welcome">
    <div class="d-block d-md-flex h-100 w-100">
      <div class="welcome-text">
        <div data-aos="fade-up" class="welcome-box">
          <h1
            data-aos="fade-down"
            [innerHTML]="'welcome.header' | translate"
            class="text-uppercase text-center text-md-start mb-4 mb-lg-5"
          ></h1>

          <div
            class="d-flex welcome-box-item  welcome-item welcome-item-{{
              index
            }}"
            *ngFor="let l of 'welcome.links' | translate; let index = i"
          >
            <div class="mb-4" data-aos="fade-right" data-aos-offset="100">
              <a
                pageScroll
                href="/{{ code }}/{{ lang }}{{ l.link }}"
                class="welcome-item-link"
                [attr.data-target]="l.link"
              >
                <span>{{ l.text }}</span>
              </a>
            </div>
          </div>
        </div>

        <div
          class="bottom-band"
          [style.background-image]="'url(/assets/images/welcome/img-bar.jpg)'"
        ></div>
      </div>

      <!-- col-l w-100 w-md-50 d-flex flex-column justify-content-center text-center -->
      <div class="col-video">
        <div class="video-container">
          <img
            [src]="'welcome.image' | translate | safe : 'resourceURL'"
            alt="Welcome image"
            class="welcome-video-image"
            data-aos-delay="50"
            data-aos-offset="200"
            data-aos="fade-in"
          />

          <div class="welcome-play">
            
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- BENEFITS 1 SECTION -->
  <section id="benefits-1">
    <div class="d-block d-md-flex">
      <div class="col-l">
        <h2
          data-aos="fade-down"
          [innerHTML]="'benefits.header' | translate"
          class="text-center text-md-start"
        ></h2>

        <img
          data-aos="fade-in"
          data-aos-offset="200"
          src="/assets/images/benefits/benefit-1.png"
          alt="Benefit 1"
          class="d-none d-md-block"
        />
      </div>

      <div class="col-r">
        <div class="benefits-box">
          <div
            data-aos="fade-up"
            class="benefits-icon align-items-center d-block d-md-flex text-center text-md-start benefits-item-{{
              l.key
            }}"
            *ngFor="let l of 'benefits.list-1' | translate"
          >
            <div>
              <img
                src="/assets/images/benefits/img-icon-{{ l.key }}.png"
                alt="{{ l.title }}"
                data-aos="fade-in"
              />
            </div>

            <div
              class="benefits-content"
              data-aos="fade-left"
              data-aos-offset="200"
            >
              <p [innerHTML]="l.description" class="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- BENEFITS 2 SECTION -->
  <section id="benefits-2">
    <div class="d-block d-md-flex">
      <div class="col-l">
        <h2
          data-aos="fade-down"
          [innerHTML]="'benefits.header' | translate"
          class="text-center text-md-start"
        ></h2>

        <img
          src="/assets/images/benefits/benefit-2.png"
          alt="Benefit 2"
          data-aos="fade-in"
          data-aos-offset="200"
        />
      </div>

      <div class="col-r">
        <div class="benefits-box">
          <div
            data-aos="fade-up"
            data-aos-offset="200"
            class="benefits-icon align-items-center d-block d-md-flex text-center text-md-start benefits-item-{{
              l.key
            }}"
            *ngFor="let l of 'benefits.list-2' | translate"
          >
            <div>
              <img
                src="/assets/images/benefits/img-icon-{{ l.key }}.png"
                data-aos="fade-in"
                alt="{{ l.title }}"
              />
            </div>

            <div
              class="benefits-content"
              data-aos="fade-left"
              data-aos-offset="200"
            >
              <p [innerHTML]="l.description" class="mb-0"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- MEMBERSHIP SECTION -->
  <section id="membership">
    <div class="membership-area {{ lang }}">
      <div class="membership-area-text">
        <h3
          data-aos="fade-down"
          [innerHTML]="'membership.header' | translate"
          class="text-center mb-5"
        ></h3>

        <div
          class="membership-icon align-items-center d-block d-md-flex text-center text-md-start mb-3"
          *ngFor="let l of 'membership.list' | translate"
        >
          <div class="membership-image">
            <img
              src="/assets/images/membership/img-icon-{{ l.key }}.png"
              alt="{{ l.description }}"
              class="img-fluid"
              data-aos="fade-in"
              data-aos-offset="200"
              *ngIf="l.key"
            />
          </div>

          <div
            class="membership-content"
            data-aos="fade-left"
            data-aos-offset="200"
          >
            <p [innerHTML]="l.description" class="mb-0"></p>
          </div>
        </div>

        <p
          [innerHTML]="'membership.legals' | translate"
          class="mb-0 legals"
          data-aos="fade-up"
        ></p>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- SKY SECTION -->
  <section id="sky">
    <h3
      class="text-center"
      data-aos="fade-down"
      [innerHTML]="'sky.header' | translate"
    ></h3>

    <div class="sky-image d-flex justify-content-center"></div>
  </section>
  <!-- -->

  <!-- ADVENTURES EN SECTION -->
  <section id="adventures" class="{{ lang }}">
    <div class="adventure-container adventure-english" *ngIf="lang == 'en'">
      <div class="col-l"></div>

      <div class="adventures-text">
        <div
          data-aos="fade-up"
          data-aos-offset="200"
          class="adventures-box aos-init aos-animate"
        >
          <h3
            data-aos="fade-down"
            class="aos-init aos-animate mt-0 mt-md-4"
            [innerHTML]="'adventures.header' | translate"
          ></h3>

          <p [innerHTML]="'adventures.description' | translate"></p>
        </div>

        <div class="adventures-video">
          <img
            data-aos="fade-in"
            data-aos-offset="200"
            src="/assets/images/adventures/img-video.png"
            class="video-link pe-cursor"
            (click)="show('adventures.video')"
            [attr.data-video]="'Adventure'"
            alt="Adventure"
          />
        </div>
      </div>
    </div>

    <div class="row adventure-container adventure-spanish" *ngIf="lang == 'es'">
      <div
        *ngFor="let l of 'adventures.videos' | translate; index as ind"
        class="col-md px-0 h-100 aos-init aos-animate"
        data-aos="zoom-in"
        data-aos-delay="50"
      >
        <div data-aos="fade-up" class="adventure-box aos-init aos-animate">
          <div class="py-4 py-md-0 m-auto" *ngIf="ind === 0">
            <img
              data-aos="fade-down"
              src="/assets/images/start/img-logo-rci.svg"
              class="rci-logo"
              alt="RCI"
            />
          </div>

          <div class="box-green" *ngIf="ind === 1">
            <p [innerHTML]="'adventures.description' | translate"></p>
          </div>

          <div class="box-red" *ngIf="ind === 2">
            <ul>
              <li
                *ngFor="let l of 'adventures.items' | translate"
                data-aos="fade-in"
                data-aos-offset="200"
              >
                {{ l }}
              </li>
            </ul>
          </div>
        </div>

        <button
          class="bg-transparent border-0 p-0 video-link"
          (click)="show(l.video)"
          [attr.data-video]="l.key"
        >
          <img
            data-aos="fade-in"
            src="/assets/images/adventures/img-{{ l.key }}.jpg"
            alt=""
            class="img-fluid"
          />

          <img
            alt="Play vidideo image"
            class="play-button"
            src="/assets/images/welcome/img-play.png"
            [attr.data-video]="'Welcome'"
            (click)="show('welcome.video')"
          />

          <h5 class="text-center mt-3">
            <strong>{{ l.title }}</strong>
          </h5>

          <div class="semi-circle"></div>
        </button>

        <div
          class="box-divider"
          [ngClass]="{
            'box-blue': ind === 0,
            'box-brown': ind === 1,
            'box-purple': ind === 2
          }"
        ></div>
      </div>
    </div>

    <div class="adventure-container adventure-portuguese" *ngIf="lang == 'pt'">
      <div class="col-l w-100 w-md-50 text-center">
        <div class="mx-auto mb-auto">
          <div class="mx-auto">
            <img
              data-aos="fade-in"
              data-aos-offset="200"
              src="/assets/images/adventures/img-circle-h.png"
              alt=""
              class="img-fluid"
            />
          </div>

          <div class="mt-4">
            <img
              data-aos="fade-in"
              data-aos-offset="200"
              src="/assets/images/adventures/img-tile-pt-1.png"
              alt=""
              class="img-fluid"
              width="300"
            />
          </div>

          <div class="mt-4">
            <img
              data-aos="fade-in"
              data-aos-offset="200"
              src="/assets/images/adventures/img-tile-pt-2.png"
              alt=""
              class="img-fluid"
              width="300"
            />
          </div>

          <div class="mt-4">
            <p
              data-aos="fade-in"
              data-aos-offset="200"
              class="aos-init aos-animate mt-4"
              [innerHTML]="'adventures.header' | translate"
            ></p>
          </div>
        </div>
      </div>

      <div class="col-r w-100 w-md-50">
        <div class="m-auto">
          <img
            data-aos="fade-in"
            data-aos-offset="200"
            src="/assets/images/adventures/img-icon.png"
            alt=""
            class="img-fluid mb-4"
          />

          <ul>
            <li
              *ngFor="let l of 'adventures.items' | translate"
              data-aos="fade-left"
              data-aos-offset="200"
              class="aos-init aos-animate mb-4"
            >
              {{ l }}
            </li>
          </ul>

          <button
            class="bg-transparent border-0 p-0 video-link"
            (click)="show('adventures.video')"
            [attr.data-video]="'Adventures'"
          >
            <img
              data-aos="fade-in"
              data-aos-offset="200"
              src="/assets/images/adventures/img-video-pt.png"
              alt=""
              class="img-fluid"
            />
          </button>
        </div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- HOTELS & NIGHTS SECTION -->
  <section id="hotels" class="{{ lang }}">
    <div class="hotels-container d-block d-md-flex">
      <div class="hotels-text w-100 w-md-50">
        <div class="hotels-box">
          <div class="hotels-description">
            <h1 data-aos="fade-down" class="text-center text-md-start">
              <a
                href="{{ 'hotels.link' | translate }}"
                target="_blank"
                [innerHTML]="'hotels.header' | translate"
              ></a>
            </h1>

            <div data-aos="fade-right" data-aos-offset="200">
              <h5 [innerHTML]="'hotels.description' | translate"></h5>
            </div>
          </div>

          <div class="hotels-list-container">
            <div class="hotels-list">
              <div
                data-aos="fade-up"
                data-aos-offset="200"
                class="d-flex align-items-center text-center text-md-start hotels-item-{{
                  l.key
                }} mb-3"
                [ngClass]="{ 'mt-0': ind === 0 }"
                *ngFor="let l of 'hotels.list' | translate; index as ind"
              >
                <div>
                  <img
                    src="/assets/images/hotels/img-icon-{{ l.key }}.png"
                    alt="{{ l.title }}"
                  />
                </div>

                <div class="hotels-content w-100 text-center text-md-start">
                  <h6 [innerHTML]="l.title"></h6>

                  <p [innerHTML]="l.description" class="mb-0"></p>
                </div>
              </div>
            </div>

            <!-- <div
              class="semi-circle d-none d-lg-block"
              *ngIf="lang === 'es'"
            ></div> -->
          </div>
        </div>
      </div>

      <div
        class="col-l d-block d-md-flex justify-content-center align-items-center w-100 w-md-50 ps-0"
      >
        <img
          data-aos="fade-in"
          data-aos-offset="200"
          src="/assets/images/hotels/img-hotels.png"
          alt="Video preview"
          class="hotel-img index-img pe-cursor"
          (click)="show('hotels.video')"
          [attr.data-video]="'Hotels'"
          *ngIf="'hotels.video' | translate; else hotelImg"
        />

        <ng-template #hotelImg>
          <img
            data-aos="fade-in"
            data-aos-offset="200"
            src="/assets/images/hotels/night.jpg"
            class="hotel-img"
            alt="Hotel image"
          />
        </ng-template>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- BOOK SECTION -->
  <section id="book">
    <div class="d-block d-md-flex align-items-center justify-content-around">
      <div class="col-l">
        <h2
          data-aos="fade-down"
          [innerHTML]="'book.header' | translate"
          class="text-center text-md-start mb-4"
        ></h2>

        <div class="video-welcome">
          <img
            data-aos="fade-in"
            data-aos-offset="200"
            [src]="'/assets/images/book/img-web-' + lang + '.png'"
            alt="Image"
          />
        </div>
      </div>

      <div class="col-r">
        <div class="book-text m-auto">
          <div class="book-box y-5 pt-md-0">
            <div class="circle-welcome mb-4">
              <a
                rel="noopener"
                target="_blank"
                href="{{ 'book.link' | translate }}"
                class="book-link"
              >
                <!-- <img src="/assets/images/book/img-rci-circle.png" alt="circle" /> -->
                <h3 class="text-center mb-0">RCI.com</h3>
              </a>
            </div>

            <div
              data-aos="fade-up"
              data-aos-offset="200"
              class="mb-3 align-items-center d-flex text-center text-md-start book-text-item-{{
                l.key
              }}"
              *ngFor="let l of 'book.list' | translate"
            >
              <div>
                <img
                  src="/assets/images/book/img-icon-{{ l.key }}.png"
                  alt="{{ l.description }}"
                />
              </div>

              <div class="book-content w-100 text-start">
                <p [innerHTML]="l.description" class="mb-0"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- PURPLE SECTION -->
  <section id="purple">
    <div
      class="purple-image d-flex justify-content-center"
      [style.background-image]="
        'url(/assets/images/purple/img-computer-' + lang + '.png)'
          | safe : 'resourceURL'
      "
    ></div>
  </section>
  <!-- -->

  <!-- SCAN -->
  <section id="scan" >
    <div class="d-block d-md-flex {{ lang }}">
      <div class="col-r">
        <div class="circle">
          <img
            data-aos="fade-in"
            data-aos-offset="200"
            src="/assets/images/scan/img-circle.png"
            alt=""
          />
        </div>

        <div class="qr-container">
          <img
            class="scan-hand"
            data-aos="fade-in"
            data-aos-offset="200"
            [src]="'/assets/images/scan/img-qr-hand.png'"
            alt=""
          />

          <div class="box-text" style="text-align: center">
            <h3
              data-aos="fade-down"
              data-aos-offset="200"
              class="aos-init aos-animate mb-3"
              [innerHTML]="'scan.title' | translate"
            ></h3>

            <div
              class="qr-image"
              [routerLink]="'/form/' + lang"
              data-aos="fade-up"
            >
              <qrcode
                [qrdata]="website + '/form/' + lang"
                [width]="280"
                [errorCorrectionLevel]="'H'"
                [colorDark]="'#F92E58'"
                [colorLight]="'#ffffff00'"
                [margin]="0"
                [cssClass]="'qr-image'"
                [errorCorrectionLevel]="'H'"
              ></qrcode>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- -->

  <!-- beach SECTION -->
  <section id="beach">
    <div class="beach-image">
      <h1
        data-aos="zoom-in"
        data-aos-offset="200"
        class="box-h1 aos-init aos-animate"
        [innerHTML]="'snow.header' | translate"
      ></h1>
    </div>

    <h3
      class="box-h3"
      data-aos="fade-down"
      data-aos-offset="200"
      [innerHTML]="'snow.small' | translate"
    ></h3>
  </section>
  <!-- -->

  <!-- CONTACT SECTION -->
  <section id="contact">
    <div class="row row-contact-1">
      <div class="col-md-4 col-contact col-contact-1 h-100" data-aos="zoom-in">
        <div class="col-contact-text">
          <h3>
            <a
              href="{{ 'contact.link' | translate }}"
              [innerHTML]="'contact.header' | translate"
              target="_blank"
            ></a>
          </h3>
        </div>
      </div>

      <div
        class="col-md-4 col-contact col-img h-100 d-none d-md-block"
        data-aos="zoom-in"
      >
        <img src="/assets/images/contact/img-col-2.jpg" alt="" />
      </div>

      <div
        class="col-md-4 col-contact col-img h-100 d-none d-md-block"
        data-aos="zoom-in"
      >
        <img src="/assets/images/contact/img-col-3.jpg" alt="" />
      </div>
    </div>

    <div class="row row-contact-2">
      <div class="col-md col-contact col-contact-4 h-100">
        <div class="col-contact-text">
          <div
            class="text-start contact-social-item mb-3 aos-init aos-animate"
            [ngClass]="{ 'mt-3': sInd === 0 }"
            data-aos="fade-up"
            *ngFor="let l of 'contact.social' | translate; index as sInd"
          >
            <a
              rel="noopener"
              href="{{ l.link }}"
              target="_blank"
              data-aos="fade-up"
              class="d-block text-center text-md-start contact-social-link"
              [attr.data-social]="l.key"
            >
              <img
                src="/assets/images/contact/img-{{ l.key }}.png"
                alt="{{ l.key }}"
                class="me-3"
                width="60"
              />
              <span class="d-block d-md-inline">{{
                l.title ? l.title : l.link
              }}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md col-contact col-contact-5 h-100">
        <div class="col-contact-text">
          <div
            class="text-start contact-social-item mb-3 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-offset="200"
            [ngClass]="{ 'mt-3': eInd === 0 }"
            *ngFor="let l of 'contact.extra' | translate; index as eInd"
          >
            <a
              rel="noopener"
              href="{{ l.link }}"
              target="_blank"
              class="d-flex flex-column text-center contact-social-link text-center"
              [attr.data-social]="l.key"
            >
              <img
                src="/assets/images/contact/img-{{ l.key }}.png"
                alt="{{ l.key }}"
                class="mx-auto"
                width="60"
              />
              <span
                class="d-block d-md-inline"
                [ngClass]="{ 'fw-bolder': l.key === 'rci' }"
              >
                {{ l.title ? l.title : l.link }}
              </span>
            </a>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-contact col-img h-100" data-aos="zoom-in">
        <img src="/assets/images/contact/img-col-6.jpg" alt="" />
      </div>
    </div>

    <div class="row row-contact-3 d-none d-md-flex">
      <div class="col-md-4 col-contact col-img h-100" data-aos="zoom-in">
        <img src="/assets/images/contact/img-col-7.jpg" alt="" />
      </div>

      <div class="col-md-4 col-contact col-img h-100" data-aos="zoom-in">
        <img src="/assets/images/contact/img-col-8.jpg" alt="" />
      </div>

      <div class="col-md-4 col-contact col-img h-100" data-aos="zoom-in">
        <img src="/assets/images/contact/img-col-9.jpg" alt="" />
      </div>
    </div>
  </section>
  <!-- -->

  <!-- VIDEO MODAL -->
  <div
    bsModal
    #mVideo="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="dialog-sizes-name"
    (onHide)="hide($event)"
  >
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <img src="/assets/images/common/img-logo.png" alt="" />
          </h5>

          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            (click)="mVideo.hide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              width="100%"
              height="315"
              [src]="video | safe : 'resourceURL'"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- -->
</div>
